import { Link } from 'react-scroll';

const ListMenu = () => {
    return (
        <>
            <li><Link activeClass="active" smooth spy to="Disclaimer">DISCLAIMER</Link></li>
            <ul>
                <li><Link activeClass="active" smooth spy to="GameIndustry">Game Industry and Cryptocurrency in Indonesia</Link></li>
            </ul>
            <li><Link activeClass="active" smooth spy to="Preamble">PREAMBLE</Link></li>
            <ul>
                <li><Link activeClass="active" smooth spy to="TheImportance">The Importance of Trusted Stable Digital Assets in Monetary Systems</Link></li>
                <li><Link activeClass="active" smooth spy to="AboutPT">About PT. Algoritma Teknologi Nusantara</Link></li>
            </ul>
            <li><Link activeClass="active" smooth spy to="AboutAnoa">ABOUT ANOA</Link></li>
            <ul>
                <li><Link activeClass="active" smooth spy to="Basics">Basics</Link></li>
                <li><Link activeClass="active" smooth spy to="BenefitsAndStrengths">Benefits and Strengths of ANOA</Link></li>
                <li><Link activeClass="active" smooth spy to="ANOA">ANOA key features</Link></li>
                <li><Link activeClass="active" smooth spy to="Tradeoffs">Tradeoffs of ANOA</Link></li>
            </ul>
            <li><Link activeClass="active" smooth spy to="DetailedTechnology">DETAILED TECHNOLOGY AND OPERATIONS</Link></li>
            <ul>
                <li><Link activeClass="active" smooth spy to="DWAToken">DWA Token Standard</Link></li>
                <li><Link activeClass="active" smooth spy to="Security">Security of ANOA Tokens</Link></li>
            </ul>
            <li><Link activeClass="active" smooth spy to="UseCase">USE CASE</Link></li>
            <ul>
                <li><Link activeClass="active" smooth spy to="BackgroundandConcept">Background and Concept</Link></li>
                <li><Link activeClass="active" smooth spy to="Model">Model</Link></li>
                <li><Link activeClass="active" smooth spy to="TokenUtility">Token Utility</Link></li>
                <li><Link activeClass="active" smooth spy to="ImplementationForGameDevelopers">Implementation For Game Developers</Link></li>
                <li><Link activeClass="active" smooth spy to="ImplementationForGameReviewers">Implementation For Gamers/ Game Reviewers</Link></li>
            </ul>
            <li><Link activeClass="active" smooth spy to="Tokoenomics">TOKENOMICS</Link></li>
            <li><Link activeClass="active" smooth spy to="Impact">IMPACT</Link></li>
            <ul>
                <li><Link activeClass="active" smooth spy to="PotentialMarket">Potential Market</Link></li>
            </ul>
            <li><Link activeClass="active" smooth spy to="Roadmap">ROADMAP</Link></li>
            <li><Link activeClass="active" smooth spy to="OurTeam">OUR TEAM</Link></li>
            <li><Link activeClass="active" smooth spy to="OurPartner">OUR PARTNER</Link></li>
            <li><Link activeClass="active" smooth spy to="Conclusion">CONCLUSION</Link></li>
        </>
    );
}

export default ListMenu;