import { useState } from 'react';

import ListMenu from '../../container/ListMenu';

const Home = () => {
    const [menu, setMenu] = useState(false);
    const showMenu = () => {
        if (menu) {
            setMenu(false);
        } else {
            setMenu(true);
        }
    }

    const hideMenu = () => {
        setMenu(false);
    }


    const imageClick = () => {
        fetch(process.env.PUBLIC_URL + '/Anoa-WhitePaper.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Anoa-WhitePaper.pdf';
                alink.click();
            })
        })
    }

    return (
        <>
            <div className="container">
                <nav className="navbars p-3 border-bottom">
                    <div className="container-fluid">
                        <div className="navbar-brands">
                            <img src={process.env.PUBLIC_URL + '/images/anoa_logo.png'} alt="anoa_logo.png" />
                        </div>
                        {/* <form className="d-flex" role="search">
                    <div id="google_translate_element"></div>
                </form> */}
                    </div>
                </nav>
                <div className="p-4">
                    <div className="btn-group mb-3">
                        <button class="btn btn-secondary dropdown-toggle w-100" type="button" onClick={showMenu}>
                            On This Page
                        </button>
                        <ul className={menu ? 'dropdown-menu p-2 show' : 'dropdown-menu p-2'}>
                            <ListMenu />
                        </ul>
                    </div>
                    <div className="row" style={{ marginBottom: 800 }} onClick={hideMenu}>
                        <div className="col-md-8">
                            <section id="Disclaimer">
                                <img style={{ width: 200, height: 50 }} src={process.env.PUBLIC_URL + '/images/download-pdf.webp'} onClick={imageClick} alt="download.png" />
                                <br></br>
                                <br></br>
                                <h1 style={{ textAlign: 'justify' }}>ANOA for Games Lover</h1>

                                <p>Last Update 6 January 2023</p>
                                <h4 style={{ textAlign: 'justify' }}>Useful Links</h4>
                                <ul>
                                    <li><a href="https://anoatoken.com/" target="_blank" > https://anoatoken.com/</a></li>
                                    <li><a href="https://whitepaper.anoatoken.com" target="_blank" > https://whitepaper.anoatoken.com</a></li>
                                    <li><a href="https://anoatoken.com/explorer" target="_blank" > https://anoatoken.com/explorer</a></li>
                                    <li><a href="https://wallet.anoatoken.com" target="_blank" >https://wallet.anoatoken.com</a></li>
                                </ul>

                                <h4 style={{ textAlign: 'justify' }}>DISCLAIMER</h4>
                                <p style={{ textAlign: 'justify' }}>
                                    Nothing in this White Paper is an offer to sell, or an offer to buy, any tokens. PT. Algoritma Teknologi Nusantara is publishing this White Paper solely to raise public feedback. If and when PT. Algoritma Teknologi Nusantara offer for sale any tokens (or a Simple Agreement for Future Tokens), it will through official offering documents, including a disclosure document and risk factors. Those official documents will also contain an updated version of this White Paper, which may differ significantly from the current version. If and when PT. Algoritma Teknologi Nusantara makes such an offering in the Indonesia, the offering likely will be available solely to accredited investors.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Nothing in this White Paper should be treated as a guarantee of how PT. Algoritma Teknologi Nusantara tokens will develop. This White Paper outlines present plans, which could change in the future, and the success of this token will depend on various factors outside PT. Algoritma Teknologi Nusantara control, including market-based factors, data-based factors and cryptocurrency global situation. Any statements about future events are based solely on PT. Algoritma Teknologi Nusantara analytical approach described in this White Paper, which may prove to be incorrect.
                                </p>

                                <section id="GameIndustry">
                                    <h6 style={{ textAlign: 'justify' }}>Game Industry and Cryptocurrency in Indonesia</h6>
                                    <p style={{ textAlign: 'justify' }}>
                                        Indonesia had a population of 272 million people. They are listed as the 4th most populated country in the world. In fact, 3.5% of the total world population is found in Indonesia.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        In 2022, Game development has become a promising industry because Indonesia has the third largest number of gamers in the world with 60 million gamers and it is still growing. It is proof that Indonesia is able to become a big market for the global game industry. The growing 60 million gamers represent 22% of Indonesian.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        Meanwhile, Indonesia comes in seventh with a crypto user base of more than 7.4 million users. That means only 2.7% of the Indonesian population owns cryptocurrency.
                                    </p>
                                </section>
                            </section>
                            <section id="Preamble">
                                <h4 style={{ textAlign: 'justify' }}>PREAMBLE</h4>
                                <p style={{ textAlign: 'justify' }}>
                                    Digital asset and stablecoin market have seized a lot of attention since Bitcoin emerged in 2009.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    As of January 2020, Bitcoin, with a market capitalization of over $600 billion has already cemented as the most highly-valued digital asset. Nonetheless, more than a decade after it was introduced to the market, it continuously affected from extreme volatility which diminishing its function as a legitimate medium of exchange.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    The issue with bitcoin is, they had missed from its original objective because bitcoin is not able to cope with stability problem meanwhile, stability is a basic characteristic that needed by mediums of exchange to be legit transactionally.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    The concept of stablecoin started as solution introduced to solved the stability problem occurred in the digital asset markets by setting its value to one to one ratio to fiat currencies or other assets, for example precious metals.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Stablecoins has already been proven to have sustainable demand by companies such as Gemini and Paxos, which offer stablecoins pegged to USD.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Correspondingly, the market will be more aware of stablecoins and the door will be wide open for more stable digital asset organizer to enter the market.
                                </p>

                                <section id="TheImportance">
                                    <h6 style={{ textAlign: 'justify' }}>The Importance of Trusted Stable Digital Assets in Monetary Systems</h6>
                                    <p style={{ textAlign: 'justify' }}>
                                        Stable digital assets such as stablecoin have been growing massively and are being used more than ever in various financial services as monetary instrument and payment medium.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        This event caught the attention of regulators around the world and have led to an extensive regulatory engagement. As the regulatory landscape changes, there are many questions about the importance of transparency, trust, and solvency.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        Needless to say, trusted digital fiat currency backed by secure blockchain technology which globally accepted and trusted is needed by the market nowadays.
                                    </p>
                                </section>

                                <section id="AboutPT">
                                    <h6 style={{ textAlign: 'justify', marginRight: '10px' }}>About PT. Algoritma Teknologi Nusantara</h6>
                                    <p style={{ textAlign: 'justify' }}>
                                        PT. Algoritma Teknologi Nusantara is Indonesian-based company built on 13th July 2022.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        PT. Algoritma Teknologi Nusantara is a legal business entity which acknowledged by law with act number 02 and legitimized by Menkumham decree with number AHU-0048224.AH.01.01.TAHUN 2022.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        We built this company hoping to introduce and create advancement in Indonesia crypto technology, to create modern crypto ecosystem, reliable, adaptable and can be used by everyone, everywhere.
                                    </p>
                                </section>

                            </section>
                            <section id="AboutAnoa">
                                <section id="Basics">
                                    <h4 style={{ textAlign: 'justify', display: 'inline-block', marginRight: '10px' }}>ABOUT ANOA </h4> Visit <a href="https://anoatoken.com/" target="_blank" >https://anoatoken.com/</a>
                                    <h6 style={{ textAlign: 'justify' }}>Basics</h6>
                                    <p style={{ textAlign: 'justify' }}>
                                        ANOA is cryptographic token that issued by PT. Algoritma Teknologi Nusantara, sanctioned and regulated by BAPPEBTI and strictly pinned to 10,000 Indonesian Rupiah (IDR).
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        ANOA is stable token that incorporate stable price with crypto technology, beneficial effectiveness of a digital asset and the oversight of Indonesian regulator.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        As a token built on DWA, ANOA inherits the speed, security, transparency, and other desirable characteristics of the DWA Blockchain. This combination of the DWA Blockchain and price stability relative to Indonesian Rupiah resulted in a digital asset that provides consumers with a stable store of value.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        The main target customers of ANOA are Indonesian Gamers who want to access global crypto exchanges. PT. Algoritma Teknologi Nusantara might collaborate with third-party market makers or independent traders in cryptocurrency exchanges to help ensure ANOA price mimics Indonesian Rupiah. Branding and market education will also help in establishing market consensus among traders that the value of ANOA tracks Indonesian Rupiah.
                                    </p>
                                </section>

                                <section id="BenefitsAndStrengths">
                                    <h6 style={{ textAlign: 'justify' }}>Benefits and Strengths of ANOA</h6>
                                    <ul style={{ textAlign: 'justify' }}>
                                        <li>ANOA is built on the DWA blockchain, which gives it the inherent security and transaction immutability of DWA.</li>
                                        <li>ANOA follows DWA token standard, which means it is very easy to integrate and inherently compatible with many existing DWA-based applications</li>
                                        <li>API to connect to active nodes are well-documented. Those APIs are already in form of JSON response endpoints which help developers save some time to build any cross-platform application.</li>
                                        <li>All transactions are executed according to the rules of DWA’s asset (token) transaction rules which eliminates human error.</li>
                                        <li>There are two types of gas fees DWA blockchain. One in form of DWA, paid by token creator; and the other one in form of ANOA, paid by transaction initiator. Therefore, ANOA ecosystem can focus on ANOA token itself without having DWA in their wallet.</li>
                                        <li>Forever flat any types of fees in the blockchain. The consistency support communities to  forecast development costs.</li>
                                    </ul>
                                </section>

                                <section id="ANOA">
                                    <h6 style={{ textAlign: 'justify' }}>ANOA key features</h6>
                                    <ol>
                                        <li>Stable value</li>
                                        <li>Flat exchangeable to 10,000 IDR</li>
                                        <li>Forever Flat Transaction Fee</li>
                                        <li>Easy redemption</li>
                                        <li>Instant transaction</li>
                                        <li>Low costs </li>
                                    </ol>
                                </section>

                                <section id="Tradeoffs">
                                    <h6 style={{ textAlign: 'justify' }}>Tradeoffs of ANOA</h6>
                                    <ul style={{ textAlign: 'justify' }}>
                                        <li>As with all tokens, transaction confirmation follows the speed of its mother coin. One Block is generated in every 60 seconds, on average, by independent accounts on network nodes in DWA. Therefore, a transaction can be valid up to 60 seconds speed.</li>
                                        <li>As part of the DWA blockchain protocol, all token transactions in the DWA network requires the token creator to pay “gas” fees in the form of DWA, the native crypto-asset of the DWA blockchain. In other word, ANOA creator must always ensure that a sum of DWA available in the account.</li>
                                    </ul>
                                </section>
                            </section>
                            <section id="DetailedTechnology">
                                <h4 style={{ textAlign: 'justify' }}>DETAILED TECHNOLOGY AND OPERATIONS</h4>

                                <section id="DWAToken">
                                    <h6 style={{ textAlign: 'justify' }}>DWA Token Standard</h6>
                                    <p style={{ textAlign: 'justify' }}>
                                        DWA Coin is a proof-of-stake cryptocurrency. DWA Coin has its unique proof-of-stake algorithm different way of design comparing with any implementation of the coin age concept used by other proof-of-stake cryptocurrencies and is resistant to so-called nothing at stake attacks. Curve25519 cryptography is used to provide a balance of security and required processing power, along with the used of SHA256 hashing algorithms.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        We will not attempt to explain the entire DWA Token standard on this paper because such discourse will be too long. Instead, we invite readers to read some of the following resources to learn more:
                                    </p>
                                    <ul>
                                        <li>What is DWA? at <a href="https://dwa.network/" target="_blank" > https://dwa.network/</a></li>
                                        <li>DWA Whitepaper <a href="https://whitepaper.dwa.network/" target="_blank" > https://whitepaper.dwa.network/</a></li>
                                        <li>DWA API<a href="https://api.dwa.network/#introduction" target="_blank" > https://api.dwa.network/#introduction</a></li>
                                        <li>DWA repository <a href="https://github.com/dwacoin/dwa" target="_blank" > https://github.com/dwacoin/dwa</a></li>
                                        <li>DWA explorer <a href="https://explorer.dwa.network/" target="_blank" > https://explorer.dwa.network/</a></li>
                                    </ul>

                                    <ul>
                                        <li>ANOA Explorer <a href="https://anoatoken.com/explorer" target="_blank" > https://anoatoken.com/explorer</a></li>
                                        <li>ANOA Wallet <a href="https://wallet.anoatoken.com/" target="_blank" > https://wallet.anoatoken.com/</a></li>
                                    </ul>

                                </section>
                                <section id="Security">
                                    <h6 style={{ textAlign: 'justify' }}>Security of ANOA Tokens</h6>
                                    <p style={{ textAlign: 'justify' }}>
                                        DWA adopts Curve25519 cryptography to provide a balance of security and required processing power, along with the used of SHA256 hashing algorithms.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        Along with Proof of Stake model used by DWA Coin, network security is governed by peers having a stake in the network. The incentives provided by this algorithm do not promote centralization in the same way that Proof of Work algorithms do, and data shows that the DWA Coin network has remained highly decentralized since its inception: a large number of unique accounts are contributing blocks to the network, and the top five accounts have generated 42% of the total number of blocks.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        The following basic principles apply to DWA Proof of Stake algorithm:
                                    </p>
                                    <ul style={{ textAlign: 'justify' }}>



                                        <li>A <i>cumulative difficulty</i> value is stored as a parameter in each block, and new level of difficulty are applied to each subsequent block based on the previous blocks value. In case of ambiguity, the network achieves consensus by selecting the block or chain fragment with the highest cumulative difficulty.</li>
                                        <li>Tokens must be stationary within an account for 1,440 blocks before they can contribute to the block generation process, this mechanism to prevent account holders from moving their stake from one account to another in purpose of manipulating their probability of block generation. Tokens that meet this criterion contribute to an account’s effective balance, and this balance is used to determine forging probability.</li>
                                        <li>Peers allow chain reorganization of no more than 720 blocks behind the current block height. This is aimed to keep an attacker from generating a new chain all the way from the genesis block, any block submitted at a height lower than this threshold is rejected.</li>
                                        <li>Transactions are deemed safe once they are encoded into a block that is 10 blocks behind the current block height. This is because the extremely low probability of any account taking control of the blockchain by generating its own chain of blocks.</li>
                                    </ul>
                                </  section>
                            </section>

                            <section id="UseCase">
                                <h4 style={{ textAlign: 'justify' }}>USE CASE</h4>

                                <section id="BackgroundandConcept">
                                    <h6 style={{ textAlign: 'justify' }}>Background and Concept</h6>
                                    <p style={{ textAlign: 'justify' }}>
                                        ANOA offers a new business model that connects gamers with game developers, in order to create many games that meet market needs at affordable prices.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        Based on the ever-increasing demand, combined with the needs of developers, ANOA provides solutions by combining crowdsourcing and crowdfunding.
                                    </p>
                                </section>

                                <section id="Model">
                                    <h6 style={{ textAlign: 'justify' }}>Model</h6>
                                    <p style={{ textAlign: 'justify' }}>
                                        By creating market transparency, ANOA enables gamers to identify new games and simultaneously identify the best developers to meet the needs of target groups.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        We started doing a lot of interviews, surveys and developing various prototypes to verify proof of concept and review and improve our business model.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        For game developers, it can be risky developing games in uncharted waters. the development of a game must be profitable. Due to the large number of freelancers, their income often fluctuates. ANOA provides solutions to all these problems at once. In fact, it helps developers to plan and ensure that the games they develop are useful and as per the needs of the market.
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        For gamers, this supports them to interact directly with developers without having to develop their own games and grow their sense of belonging to the game they tested. ANOA is also an ideal market research tool for game developers because of its traits which is a test benchmark point; it's possible for developers to see if their game will be a success before having to commit to any details.
                                    </p>
                                </section>

                                <section id="TokenUtility">
                                    <h6 style={{ textAlign: 'justify' }}>Token Utility</h6>
                                    <ul style={{ textAlign: 'justify' }}>
                                        <li><b>PickMe:</b> Developers can register the concept or prototype of games that they developed to gain reviews via user experience, beside that game developers can rewards gamers that have reviewed their games by giving them ANOA token so gamers can feel rewarded for their reviews</li>
                                        <li><b>Votes:</b> Gamers can vote for games that will be developed and give review based on their experiences. Moreover, gamers can get rewards in the form of ANOA token after they reviewed any game</li>
                                        <li>Gamers use their ANOA tokens to take advantage of various in-game features</li>
                                    </ul>
                                </section>

                                <section id="ImplementationForGameDevelopers">
                                    <h6 style={{ textAlign: 'justify' }}>Implementation For Game Developers</h6>
                                    <img src={require("../../images/implementation_for_game_developers.png")} />
                                </section>

                                <section id="ImplementationForGameReviewers">
                                    <h6 style={{ textAlign: 'justify' }}>Implementation For Gamers/ Game Reviewers</h6>
                                    <img src={require("../../images/implementation_for_gamers_game_reviewers.png")} />
                                </section>

                            </section>


                            <section id="Tokoenomics">
                                <h4 style={{ textAlign: 'justify' }}>TOKENOMICS</h4>
                                <p style={{ textAlign: 'justify' }}>
                                    Total Supply: 10,000,000 ANOA
                                </p>
                                <table>
                                    <tr>
                                        <td></td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>%</td>
                                        <td style={{
                                            textAlign: "right",
                                        }} ><b>ANOA</b></td>
                                        <td><b>Account</b></td>
                                    </tr>
                                    <tr>
                                        <td>Private Sale</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>5</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>500,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/17151679250856249666" target="_blank" >17151679250856249666</a></td>
                                    </tr>
                                    <tr>
                                        <td>Presale</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>20</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>2,000,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/6227181002903661987" target="_blank" >6227181002903661987</a></td>
                                    </tr>
                                    <tr>
                                        <td>DEX</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>15</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>1,500,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/17045149829443101311" target="_blank" >17045149829443101311</a></td>
                                    </tr>
                                    <tr>
                                        <td>CEX</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>20</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>2,000,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/6998523446257279999" target="_blank" >6998523446257279999</a></td>
                                    </tr>
                                    <tr>
                                        <td>Marketing & Airdrop</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>4</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>400,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/3335115312429519739" target="_blank" >3335115312429519739</a></td>
                                    </tr>
                                    <tr>
                                        <td>Developers Community Building</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>4</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>400,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/9512381202339233815" target="_blank" >9512381202339233815</a></td>
                                    </tr>
                                    <tr>
                                        <td>Gamers Comunity Building</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>4</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>400,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/2352042181888832018" target="_blank" >2352042181888832018</a></td>
                                    </tr>
                                    <tr>
                                        <td>Development</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>10</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>1,000,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/10976317133437487383" target="_blank" >10976317133437487383</a></td>
                                    </tr>
                                    <tr>
                                        <td>Operational & Legal</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>3</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>300,00</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/6314633203350324680" target="_blank" >6314633203350324680</a></td>
                                    </tr>
                                    <tr>
                                        <td>R&D and Collecting Information</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>5</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>500,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/4895140912420113117" target="_blank" >4895140912420113117</a></td>
                                    </tr>
                                    <tr>
                                        <td>Reserve</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>10</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>1,000,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/4157427479160188573" target="_blank" >4157427479160188573</a></td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            textAlign: "right"
                                        }}><b>TOTAL</b></td>
                                        <td style={{
                                            textAlign: "right"
                                        }}><b>100%</b></td>
                                        <td style={{
                                            textAlign: "right"
                                        }}><b>10,000,000</b></td>
                                    </tr>
                                </table>
                                <br></br>
                                <span>Visit</span> <a href="https://anoatoken.com/explorer" target="_blank" >https://anoatoken.com/explorer</a>
                                <br></br>
                                <br></br>
                                <img src={require("../../images/tokenomics.png")} />

                                <section id="PrivateSale">
                                    <h6 style={{ textAlign: 'justify' }}>Private Sale</h6>
                                    <p style={{ textAlign: 'justify' }}>
                                        We have raised 5,000,000,000IDR to cover expenses for the coming 6 months for the core team, development for the first functional beta, and marketing to run the Airdrop campaign and the first Pre-Sale round.
                                    </p>
                                </section>

                            </section>

                            <section id="Impact">
                                <h4 style={{ textAlign: 'justify' }}>IMPACT</h4>
                                <section id="PotentialMarket">
                                    <h6 style={{ textAlign: 'justify' }}>Potential Market</h6>
                                    <p style={{ textAlign: 'justify' }}>
                                        The focus target group is composed of:
                                    </p>
                                    <ul>
                                        <li>Gamers</li>
                                        <li>Games Developers</li>
                                    </ul>
                                </section>
                            </section>


                            <section id="Roadmap">
                                <h4 style={{ textAlign: 'justify' }}>ROADMAP</h4>
                                <h6 style={{ textAlign: 'justify' }}>Previous Milestones:</h6>
                                <h6 style={{ textAlign: 'justify' }}>Phase 1</h6>
                                <ul>
                                    <li>ANOA team building</li>
                                    <li>R&D and Collecting Information</li>
                                    <li>ANOA Blockchain Development</li>
                                    <li>Whitepaper</li>
                                </ul>
                                <h6 style={{ textAlign: 'justify' }}>Phase 2</h6>
                                <ul>
                                    <li>Tokenomics & ANOA Website Development</li>
                                    <li>Social Media Campaign</li>
                                    <li>Penetration Test</li>
                                    <li>CEX Listing Preparation</li>
                                </ul>
                                <h6 style={{ textAlign: 'justify' }}>Phase 3</h6>
                                <ul>
                                    <li>Community Building (Developers)</li>
                                    <li>Community Building (Gamers)</li>
                                    <li>CEX Listing Process</li>
                                    <li>ANOA Platform Building</li>
                                    <li>Presale ANOA Token</li>
                                </ul>
                                <h6 style={{ textAlign: 'justify' }}>Future Milestones:</h6>
                                <h6 style={{ textAlign: 'justify' }}>Phase 4</h6>
                                <ul>
                                    <li>CEX Listing Done</li>
                                    <li>Bigger Marketing Campaign</li>
                                    <li>Community Events</li>
                                    <li>Audit Tech</li>
                                    <li>Listing on Coingecko and Coinmarketcap</li>
                                    <li>Update Roadmap is Coming</li>
                                </ul>
                            </section>

                            <br></br>

                            <img src={require("../../images/roadmap.png")} />

                            <section id="OurTeam">
                                <h4 style={{ textAlign: 'justify' }}>OUR TEAM</h4>
                                <p style={{ textAlign: 'justify' }}>
                                    Founder: Gunatah Iksan
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    CEO & Co-founder: Juan Putra
                                </p>
                            </section>

                            <section id="OurPartner">
                                <h4 style={{ textAlign: 'justify' }}>OUR PARTNER</h4>
                                <ul>
                                    <li>Dewa Games</li>
                                </ul>
                            </section>

                            <section id="Conclusion">
                                <h4 style={{ textAlign: 'justify' }}>CONCLUSION</h4>
                                <p style={{ textAlign: 'justify' }}>
                                    PT Algoritma Teknologi Nusantara’s professional teams have vision to reach and educate Indonesia people about crypto technology importance and create innovation through our crypto product.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Through PT Algoritma Teknologi Nusantara’s ANOA token, all parties in gaming industry will be facilitated with simple and userfriendly payment system in ANOA wallet to complete their transaction and publicly available in blockchain.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    PT Algoritma Teknologi Nusantara believes that ANOA token will contribute greatly in reducing gap between gamers numbers and crypto currency users.
                                </p>
                            </section>


                        </div>
                        <div className="col-md border-start">
                            <div className="sidebar">
                                <p>ON THIS PAGE</p>
                                <ul>
                                    <ListMenu />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
